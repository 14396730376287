// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Text {
    font-family: 'Avenir', sans-serif;
    p {
      font-size: $font-size-4;
      margin: 1rem 0;
      line-height: 2.6rem;
    }

    &.is-small {

      p {
        font-size: $font-size-5;
      }
    }

    &.is-medium {

      p {
        font-size: $font-size-2;
      }
    }

    &.is-large {

      p {
        font-size: 7.4rem;
        line-height: 3rem;
        margin: 2rem 0 0;
      }
    }

    &.is-centered {

      p {
        text-align: center;
      }
    }

    &.is-bold {

      p {
        font-family: 'brandon_grotesquebold';
      }
    }

    &.is-upper {

      p {
        text-transform: uppercase;
      }
    }

    &.is-gray {

      p {
        color: $color-font-gray;
      }
    }

    &.is-white {

      p {
        color: white;
      }
    }

    &.margin-large {

      p {
        margin: 3rem 0;
      }
    }
  }

// --------------------------------------------------------- |