// --------------------------------------------------------- |

// --------------------------------------------------------- |

  .Preloader {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .Preloader-image {
      max-width: 20rem;
    }
  }

// --------------------------------------------------------- |