// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Picture-preview {
    display: inline-flex;
    border-radius: .2rem;
    margin: 0 .4rem .4rem 0;
    max-width: 104px;
    max-height: 104px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  
    border: 3px solid #fff;
    box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
   
    color:#333;
    cursor: pointer;

    .Picture-container {
      display: flex;
      min-width: 0;
      overflow: hidden;

      .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .Picture-error {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(214,2,2,.8);
      text-align: center;
      color: white;
      padding: .5rem;
    }

    .Picture-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 700;
    }

    .Picture-success {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-animation: slideIn 1s forwards;
      -moz-animation: slideIn 1s forwards;
      -ms-animation: slideIn 1s forwards;
      -o-animation: slideIn 1s forwards;
      animation: slideIn 1s forwards;
    }

    .Picture-delete {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 600;
      background-color: rgba(0,0,0,.7);

      & > * {
        position: absolute;
        top: .5rem;
        right: .5rem;
      }
    }

    &:hover {
      cursor: pointer;

      .Picture-delete {
        opacity: 1;
      }
    }
  }

  @-webkit-keyframes slideIn {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      -moz-transform: translateY(40px);
      -ms-transform: translateY(40px);
      -o-transform: translateY(40px);
      transform: translateY(40px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @-moz-keyframes slideIn {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      -moz-transform: translateY(40px);
      -ms-transform: translateY(40px);
      -o-transform: translateY(40px);
      transform: translateY(40px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      -moz-transform: translateY(40px);
      -ms-transform: translateY(40px);
      -o-transform: translateY(40px);
      transform: translateY(40px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

// --------------------------------------------------------- |