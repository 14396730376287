// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Progress-bar {
    width: 100%;
    background-color: #cfcfcf;
    height: .2rem;
    border-radius: .3rem;
    position: relative;
    box-shadow: $base-shadow;

    .Progress-value {
      position: absolute;
      left: 0;
      top: 0;
      height: .2rem;
      border-radius: .3rem;
      background-color: $color-primary;
      width: 0%;
    }
  }

// --------------------------------------------------------- |