// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Heading {
    font-family: 'brandon_grotesque_regularRg';

    h1, h2, h3 {
      margin: 1.5rem 0;
      letter-spacing: 1px;
    }

    h1 {
      font-size: $font-size-1;
      // font-weight: 700;
     
    }

    h2 {
      font-size: $font-size-2;
    }

    h3 {
      font-size: $font-size-3;
      letter-spacing: 1px;
      padding-bottom: 5px;
    
      width: 100%;
      text-transform: uppercase;
    }

    &.is-primary {

      h1, h2, h3 {
        color: $color-font;
      }
    }

    &.is-centered {

      h1, h2, h3 {
        text-align: center;
      }
    }
  }

// --------------------------------------------------------- |