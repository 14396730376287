@import 'views/assets/styles/variables';
@import 'views/assets/styles/normalize';

// --------------------------------------------------------- |

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    overflow-y: scroll;
  }

  body {
    margin: 0;
    padding: 0;
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    background-color: $base-background;
    overflow-x: hidden;
    background-color: #fcf4ed;
  }

  a {
    color: $base-font-color;
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  @media only screen and (max-width: 600px) {

    html {
      font-size: 8px;
    }
  }

// --------------------------------------------------------- |