// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Notification {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-third;
  }

// --------------------------------------------------------- |