// --------------------------------------------------------- |

  @import './ionicons/ionicons.scss';

// --------------------------------------------------------- |

  @font-face {
    font-family: 'avenir';
    src: url('./avenir/avenir-roman-webfont.eot?#iefix') format('embedded-opentype'),
      url('./avenir/avenir-roman-webfont.woff') format('woff'),
      url('./avenir/avenir-roman-webfont.ttf') format('truetype'),
      url('./avenir/avenir-roman-webfont.svg#avenir-roman-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquebold_italic';
    src: url('./brandon/brandon_bld_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_bld_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquebold';
    src: url('./brandon/brandon_bld-webfont.woff2') format('woff2'),
      url('./brandon/brandon_bld-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesqueblack_italic';
    src: url('./brandon/brandon_blk_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_blk_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesqueblack';
    src: url('./brandon/brandon_blk-webfont.woff2') format('woff2'),
      url('./brandon/brandon_blk-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquelight_italic';
    src: url('./brandon/brandon_light_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_light_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquelight';
    src: url('./brandon/brandon_light-webfont.woff2') format('woff2'),
      url('./brandon/brandon_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesqueMdIt';
    src: url('./brandon/brandon_med_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_med_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquemedium';
    src: url('./brandon/brandon_med-webfont.woff2') format('woff2'),
      url('./brandon/brandon_med-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesqueRgIt';
    src: url('./brandon/brandon_reg_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_reg_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesque_regularRg';
    src: url('./brandon/brandon_reg-webfont.woff2') format('woff2'),
      url('./brandon/brandon_reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquethin_italic';
    src: url('./brandon/brandon_thin_it-webfont.woff2') format('woff2'),
      url('./brandon/brandon_thin_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'brandon_grotesquethin';
    src: url('./brandon/brandon_thin-webfont.woff2') format('woff2'),
      url('./brandon/brandon_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'ionicons';
    src: url('./ionicons/ionicons.eot');
    src: url('./ionicons/ionicons.eot') format('embedded-opentype'),
      url('./ionicons/ionicons.ttf') format('truetype'),
      url('./ionicons/ionicons.woff') format('woff'), url('./ionicons/ionicons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

// --------------------------------------------------------- |
