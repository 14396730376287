// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Button {
    display: inline-block;
    margin: 1.5rem 0;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2.5rem;
      height: 4.5rem;
      border-radius: 3.1rem;
      transition: all .2s;
      user-select: none;
      font-weight: 600;
      outline: none;
      cursor: pointer;
      transform-origin: 50% 50%;
      color: white;
      background-color: $color-primary;
      font-family: $base-font-family;
      font-size: $font-size-4;
      line-height: 2.6rem;
      min-width: 27rem;
      text-decoration: none;

      .Button-label {
        padding: 0 1rem;
      }
    }

    &.is-primary {
      a,
      button,
      .Button-ghost {
        background-color: $color-primary;
        color: rgba(255,255,255,1);
        border: .2rem solid $color-primary;

        &:hover {
          box-shadow: 0 2px 4px rgba(3,27,78,.06);
        }
      }
    }

    &.is-secondary {
      a,
      button,
      .Button-ghost {
        background-color: $color-secondary;
        color: rgba(255,255,255,1);
        border: .2rem solid $color-secondary;

        &:hover {
          box-shadow: 0 2px 4px rgba(3,27,78,.06);
        }
      }
    }

    &.is-upper {
      a,
      button,
      .Button-ghost {
        text-transform: uppercase;
        letter-spacing: .2em;
      }
    }

    &.is-centered {
      a,
      button,
      .Button-ghost {
        margin: 0 auto;
      }
    }
  }

// --------------------------------------------------------- |