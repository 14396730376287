// --------------------------------------------------------- |

@import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Dropzone {

    .Dropzone-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 1.6rem;
    }

    .Dropzone-input {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: center;
      align-items: center;
      width: 104px;
      height: 104px;
      border: 1px dashed rgba(51, 51, 51, 0.31);
      box-sizing: border-box;
      cursor: pointer;
      margin:5px;
      .Dropzone-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      &:focus {
        outline: none;
      }
    }

    .Dropzone-action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem 0 0;
    }
  }

// --------------------------------------------------------- |