// --------------------------------------------------------- |

  @import 'views/assets/styles/variables';

// --------------------------------------------------------- |

  .Icon {
    font-size: 5.4rem;

    &.is-secondary {
      color: $color-secondary;
    }

    &.is-inverse {
      color: white;
    }

    &.is-small {
      font-size: 2.5rem;
    }
  }

// --------------------------------------------------------- |