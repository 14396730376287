// Colors -------------------------------------------------- |

  $color-primary: #18B2BB;
  $color-secondary: #f0a;
  $color-third: #F5EEC2;
  $color-fourth: #FF8172;
  $color-error: #d60202;
  $color-font: #333333;
  $color-font-gray: rgba(51, 51, 51, 0.38);
  $color-background: #C3F3E3;
  $color-background-2: #F5F3F2;

// Font Sizes ---------------------------------------------- |

  $font-size-1: 2.4rem;
  $font-size-2: 2.0rem;
  $font-size-3: 1.6rem;
  $font-size-4: 1.4rem;
  $font-size-5: 1.0rem;

// Base vars ----------------------------------------------- |

  $base-background: #fff;
  $base-transition: all .2s;
  $base-font-family: 'brandon_grotesquenormal', 'avenir', Arial, Helvetica, sans-serif;
  $base-font-size: $font-size-4;
  $base-font-weight: 400;
  $base-font-color: $color-font;
  $base-selection-color: #fff;
  $base-selection-background: $color-primary;
  $base-shadow: 0 6px 30px rgba(53,118,174,.1);
  $base-shadow-2: 0 6px 30px rgba(53,118,174,.7);
  $app-header-height: 8rem;

// --------------------------------------------------------- |